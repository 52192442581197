import { Injectable } from '@angular/core';
import { UrlTree, CanActivate, Router } from '@angular/router';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { filter, Observable, of, switchMap } from 'rxjs';

import { User } from '../model/user';
import { UserStoreService } from '../store/user-store.service';
import { isNonNull } from './redeem-blocked.guard';

enum CompanyTier {
  AUTHORIZED = 1,
  SILVER = 2,
  PLATINUM = 3,
  GOLD = 4,
}

@Injectable({
  providedIn: 'root',
})
export class TierAllowedGuard implements CanActivate {
  constructor(private router: Router, private userStore: UserStoreService, private authStore: AuthStoreService) {}

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authStore.isRegularUser$.pipe(
      filter(isNonNull),
      switchMap((isRegularUser: boolean) => {
        if (isRegularUser) {
          if (
            this.authStore.userSettings?.country.alpha2Code === 'IN' &&
            (this.isCompanyTier(CompanyTier.AUTHORIZED) || this.isCompanyTier(CompanyTier.SILVER))
          ) {
            this.router.navigateByUrl('/gold-or-platinum-tier-allowed');
            return of(false);
          }
        }

        return of(true);
      })
    );
  }

  private isCompanyTier(companyTier: CompanyTier): boolean {
    const company = this.getPrimaryCompany();

    if (company) {
      if (company.leapCompanyDetails && company.leapCompanyDetails.companyPcsdTierId === companyTier) {
        return true;
      }
    }

    return false;
  }

  private getPrimaryCompany(): any {
    const { companies } = this.authStore.userSettings?.user as User;
    let company;

    if (companies) {
      if (companies.length > 1) {
        company = companies.find((c) => c.isPrimary);
      } else if (companies.length === 1) {
        company = companies[0];
      }

      if (!company) {
        company = companies[0];
      }
    }

    return company;
  }
}
