import { Component } from '@angular/core';
import { AccountSummary } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@UntilDestroy()
@Component({
  selector: 'app-account-summary-widget',
  templateUrl: './account-summary-widget.component.html',
  styleUrls: ['./account-summary-widget.component.scss'],
})
export class AccountSummaryWidgetComponent {
  currentYear = new Date().getFullYear() - 1;
  previousYears = [this.currentYear - 2, this.currentYear - 3];

  accountSummary$ = this.userStore.accountSummary$;

  currentYearAccountSummary$ = this.userStore.yearAccountSummaries$.pipe(
    filter(Boolean),
    map((yearAccountSummaries) =>
      yearAccountSummaries.filter((yearAccountSummary) => yearAccountSummary.programYear === this.currentYear)
    ),
    map((yearAccountSummaries) => yearAccountSummaries[1])
  );

  previousYearsAccountSummary: AccountSummary[] = [];
  previousYearsAccountSummary$ = this.userStore.yearAccountSummaries$
    .pipe(filter(Boolean))
    .subscribe((yearAccountSummaries) => {
      this.previousYearsAccountSummary = yearAccountSummaries
        .filter(
          (yearAccountSummary) =>
            yearAccountSummary.programYear === this.currentYear - 2 ||
            yearAccountSummary.programYear === this.currentYear - 3
        )
        .sort((a, b) => b.programYear - a.programYear);
    });

  businessRules$ = this.userStore.businessRules$.pipe(filter(Boolean), untilDestroyed(this));

  constructor(private userStore: UserStoreService) {}
}
