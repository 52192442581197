import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthStoreService, Content, ContentGqlService, ContentStoreService } from '@motivforce/mx-library-angular';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { IsLoadingService } from '@service-work/is-loading';
import { filter, map, skip, take } from 'rxjs';
import { PromotionStoreService } from 'src/app/core/store/promotion-store.service';

import { PromotionUltimateRaceSummaryData } from '../../../model/promotion/promotion-ultimate-race-summary-data';

@UntilDestroy()
@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {
  user$ = this.authStore.userSettings$.pipe(map((settings) => (settings ? settings.user : null)));

  profileMenu: any;
  intelPremiumRewardsSidebarData: any;
  ssgEmeaSidebarData: any;
  valenciaSummaryData: any;
  ultimateRaceSummaryData: PromotionUltimateRaceSummaryData;

  showRewardCatalogWidgets = false;
  showMastercarWidgets = false;

  constructor(
    private offcanvasService: NgbOffcanvas,
    private authStore: AuthStoreService,
    private router: Router,
    private isLoadingService: IsLoadingService,
    private contentStore: ContentStoreService,
    private contentGql: ContentGqlService,
    private translateService: TranslateService,
    private promotionStore: PromotionStoreService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(() => {
        this.offcanvasService.dismiss();
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.showRewardCatalogWidgets =
            event.url.includes('rewards-catalogue') ||
            event.url.includes('points-statement') ||
            event.url.endsWith('take-a-course') ||
            event.url.endsWith('workstation-expert-partner-lounge');
          this.showMastercarWidgets = event.url.includes('my-lenovo-mastercard');
        }
      });

    this.contentStore.headerMenu$.pipe(untilDestroyed(this)).subscribe(() => {
      this.getMenu(this.translateService.currentLang);
    });

    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe((translation) => {
      if (translation?.lang) {
        this.getMenu(translation.lang);
      }
    });

    this.promotionStore.currentPromotion$.pipe(skip(1), take(1)).subscribe((promotion: any) => {
      if (promotion.promotionProperties) {
        this.intelPremiumRewardsSidebarData = promotion.promotionProperties.find(
          (property: any) => !!property.intelPremiumRewardsSidebarData
        )?.intelPremiumRewardsSidebarData;

        this.ssgEmeaSidebarData = promotion.promotionProperties.find(
          (property: any) => !!property.ssgEmeaSidebarData
        )?.ssgEmeaSidebarData;

        this.valenciaSummaryData = promotion.promotionProperties.find(
          (property: any) => !!property.valenciaSummaryData
        )?.valenciaSummaryData;

        this.ultimateRaceSummaryData = promotion.promotionProperties.find(
          (property: any) => !!property.ultimateRaceAIPCSummaryData
        )?.ultimateRaceAIPCSummaryData;
      }
    });
  }

  open(content: any) {
    this.offcanvasService.open(content, {
      ariaLabelledBy: 'offcanvas-basic-title',
      position: 'end',
      panelClass: 'profile-menu-panel',
      scroll: true,
    });
  }

  logOut() {
    this.authStore.authenticatedUser$.pipe(untilDestroyed(this), skip(1), take(1)).subscribe(() => {
      window.location.reload();
    });

    this.authStore.signOut();
  }

  getMenu(language: string): void {
    const siteUrl = new URL(window.location.href);
    const bannerMenuPath = `${siteUrl.origin}/menu-profile-menu`;

    this.isLoadingService.add(
      this.contentGql.getContentByUrlPath(bannerMenuPath, language, true).subscribe((bannerMenuResponse) => {
        this.profileMenu = {
          items: this.transformMenuItems(bannerMenuResponse.children),
        };
      })
    );
  }

  private transformMenuItems(menuItems: Content[]): any[] {
    return menuItems.map((menuItem) => {
      const labelField = menuItem.fields.find((f) => f.name === 'Text');
      const label = labelField ? labelField.value.value : '(no label)';
      const iconField = menuItem.fields.find((f) => f.name === 'Menu Item Icon');

      return {
        url: menuItem.properties.url?.value,
        label,
        icon: iconField && iconField.value ? iconField.value.url : '',
      };
    });
  }
}
