import { Injectable } from '@angular/core';
import { UrlTree, CanActivate, Router } from '@angular/router';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { filter, Observable, of, switchMap } from 'rxjs';

import { User } from '../model/user';
import { UserStoreService } from '../store/user-store.service';
import { isNonNull } from './redeem-blocked.guard';

@Injectable({
  providedIn: 'root',
})
export class UserBlockedGuard implements CanActivate {
  constructor(private router: Router, private userStore: UserStoreService, private authStore: AuthStoreService) {}

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authStore.isRegularUser$.pipe(
      filter(isNonNull),
      switchMap((isRegularUser: boolean) => {
        if (isRegularUser && this.isBlockedUser()) {
          this.router.navigateByUrl('/program-unavailable');
          return of(false);
        }

        return of(true);
      })
    );
  }

  private isBlockedUser() {
    return (this.authStore.userSettings?.user as User)?.statusId === 3;
  }
}
